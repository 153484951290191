import {shopInfo, linkNavs, areaName} from "../core/defines";
import { Link } from "react-router-dom";
import { telBlockPc } from "../core/core"
import { NavLinkMaker } from "../hepers/blockmaker";
function toggleNav(){
    const targets = [document.getElementById("hamburger"),
                     document.getElementById("nav-drower"),
                     document.getElementById("nav-closer")];

    targets.map((el)=>{el.classList.toggle("active")});
}

function Header(){


    return(
        <>
            <header id="g-nav">
                <div className="box">
                    <h1><Link to="/home"><img className="logo" src="/img/mb-logo-kumamoto.png?v=20240209" alt={ shopInfo.name } /></Link></h1>

                    <nav className="grid">
                    {/* grid構成, 親divは可読性の為だけの入れ子 */}
                        <div className="pc"><p className="pc txt-right">{ areaName }のソープランド【{ shopInfo.name }】</p></div>
                        <div>
                            { telBlockPc() }
                            <button className="btn tel sp"><a href={"tel:" + shopInfo.tel}><img src="/img/ico/btn-tel.svg" /><span>電話する</span></a></button>
                        </div>
                        <div>
                            <a className="btn map sp" href="/access"><img className="sp" src="/img/ico/btn-map.svg" /><span>マップ</span></a>
                        </div>
                        <button id="hamburger" className="btn menu sp" onClick={ ()=>{toggleNav()} }><img src="/img/ico/btn-menu.svg" /><span>メニュー</span></button>
                    </nav>
                </div>
                <nav id="nav-drower" className="txt-center sp" onClick={ ()=>{toggleNav()} }>
                    <h2><img src="/img/ico/btn-menu.svg" />MENU</h2>
                    <ul className="wrapper sp">
                        { linkNavs.map((link)=>{
                            return(
                                <li className="link">
                                    <NavLinkMaker data={link}>
                                        <img src={ link.img } /><span>{ link.name }</span>
                                    </NavLinkMaker>
                                </li>
                            )
                            }
                        )}
                    </ul>
                </nav>
                <div id="nav-closer" className="sp" onClick={ ()=>{toggleNav()} }></div>
            </header>
            <div id="dummy-hd"></div>
        </>
    );
}
export default Header;