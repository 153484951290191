import { Link } from "react-router-dom"
import { hvnUriRoot } from "../core/defines"
import { NavLinkMaker } from "../hepers/blockmaker";

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップ", nameEn: "TOP", path: "/home", blank: false, img: ""},
                        {nameJp: "出勤", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: hvnUriRoot + "/system", blank: true, img: ""},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
                        {nameJp: "写メ日記",     nameEn: "DIARY", path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
                        {nameJp: "口コミ",       nameEn: "REVIEWS", path: hvnUriRoot + "/reviews", blank: true, img: "/img/ico/i-review.png"},
                        {nameJp: "ネット予約",   nameEn: "RESERVE", path: hvnUriRoot + "/A6ShopReservation", blank: true, img: "/img/ico/i-reserve.png"},
                        {nameJp: "女子求人",     nameEn: "女子求人", path: "https://kyusyu-okinawa.qzin.jp/mkumamoto/?v=official", blank: true, img: "/img/ico/i-rec-women.png", tagForced: true, requireTag: "vanilla"}, 
                        {nameJp: "男子求人",     nameEn: "男子求人", path: "https://mensheaven.jp/10/marimburu_kumamoto/?of=y", blank: true, img: "/img/ico/i-rec-men.png"},              
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        return(
                            <li key={el.nameEn}>
                                <NavLinkMaker data={el}>
                                    {el.nameJp}<span>{el.nameEn}</span>
                                </NavLinkMaker>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );
}

export default PcNav;