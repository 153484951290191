import { shopInfo, areaName, hvnUriRoot,slideArr } from "./core/defines";

const enterbanner = slideArr[0].img.imgPathSp
// console.log(enterbanner)

const hvnEnter = "https://www.cityheaven.net/hyogo/A2804/A280401/heaven_himegi/?of=y2"

const navs = [];
function Enterance(){
    return(
        <article id="enterence" className="txt-center">
            <header>{ areaName }のソープランド 【{ shopInfo.name }】</header>
            <picture>
                <source media="(max-width: 720px)" srcSet={enterbanner} />
                <source media="(min-width: 721px)" srcSet={enterbanner} />
                <img src={enterbanner} alt={ `${ areaName }のソープランド 【${ shopInfo.name }】` } />
            </picture>
            <div className="group-logo-wrap -mt">
                <a href="https://marineblue-g.com/" target="_blank">
                    <img src="/img/marin-group-logo.png" alt="マリングループ ロゴ" width="250" height="40"/>
                </a>
            </div>

            <div className="attention-container">
                <div className="attention-box">
                    当店は暴力団を含む反社会的団体<br/>
                    及びスカウト等との関わりは一切ございません。
                </div>
                <div class="attention-wrap">
                    <div class="title">■加盟協会・団体</div>
                    <ul className="group-box">
                        <li>・全日本特殊浴場協会連合会</li>
                        <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                        <li>・一般社団法人全国防犯健全協力会</li>
                        <li>・東京防犯健全協力会</li>
                    </ul>
                </div>
            </div>
            <div className="wrappar">
                <p className="w-100"><img src="/img/18txt.png" width="50" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" /></p>
                <p className="kin-txt">当サイトはアダルトコンテンツを含みます。<br/>18歳未満の方のご利用は固く禁じられています。</p>
                <a className="exit" href="https://www.yahoo.co.jp" nofollow>18歳未満の方は<u>コチラ</u>からご退出ください。</a>

                <nav className="enter">
                    <ul>
                        <li><a className="btn to-home" href="/home"><span>ENTER</span>18歳以上のお客様ページ</a></li>
                        {/*
                            <li><a className="btn to-hvn" href={ hvnEnter }>ヘブン版ページはコチラ</a></li>
                        */
                        }
                    </ul>
                </nav>

                    <div className="bnr">
                        <a rel="nofollow" href="https://kyusyu-okinawa.qzin.jp/mkumamoto/?v=official" target="_blank">
                            <img src="/img/20250401_entrance-recruit.gif" alt="女性求人情報はコチラ" />
                        </a>
                    </div>

                    <div className="bnr">
                        <a rel="nofollow" href="https://kyusyu-okinawa.qzin.jp/mkumamoto/?v=official" target="_blank">
                            <img src="https://ad.qzin.jp/img/bnr_sp_sample_vanilla.jpg" width="640" border="0" alt="マリン熊本本店で稼ぐならバニラ求人" />
                        </a>
                    </div>
                    <div className="bnr">
                        <a href="https://ranking-deli.jp/fuzoku/style5/43/shop/42063/" target="_blank">
                            <img src="https://ranking-deli.jp/assets/img/user/link/bnr30050.jpg" width="300" height="50" border="0" alt="【マリン熊本本店】駅ちか版はコチラ" />
                        </a>
                    </div>


                {
                    /*
                    <section className="recruit">
                        <ul className="box">
                            <li><a className="btn women" href="https://www.girlsheaven-job.net/hyogo/ma-47/sa-463/heaven_himegi/?of=y"><span>女性求人</span>ENTER</a></li>
                            <li><a className="btn men"   href="https://mensheaven.jp/8/heaven_himegi/?of=y"><span>男性求人</span>ENTER</a></li>
                        </ul>
                    </section>
                    */
                }
                
                {/* ヘブンバナー類 直書きのstyleの記述方が違うので注意。特にプロパティ名がlowCamelCaseの点に注意 */}
                <div className="enterbanner">
                    <div className="bnr">
                        <a href="https://marineblue-g.com/" target="_blank" rel="noreferrer noopener">
                            <img src="https://marineblue-g.com/img/group-banner_468x150.webp" width="400px" alt="マリングループ" />
                        </a>
                    </div>
                    <div className="bnrNarrow">
                        <a href="https://www.cityheaven.net/kumamoto/A4301/A430101/marimburu_kumamoto/?of=y2" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/heavennetlink_ohp_red01.png" width="400" height="68" border="0" alt="ヘブンネット" />
                        </a>
                    </div>

                    <div className="bnr">
                        <a href="https://mensheaven.jp/10/marimburu_kumamoto/?of=y" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/jobheavenlink_ohp02.png" width="400" height="68" border="0" alt="ジョブヘブン" />
                        </a>
                    </div>
                    <p style={{textAlign: "center", margin: 0}}>
                        <span style={{fontSize: "16px", color: "#333333"}}>相互リンク</span>
                    </p>
                    <div className="bnrHalfWrap">
                        <div class="bnrHalf">
                            <a href="https://www.cityheaven.net/kumamoto/A4301/shop-list/biz4/" target="_blank">
                                <img src="https://img.cityheaven.net/img/mutual_link/fukui/200_40.jpg" width="200" height="40" border="0" alt="熊本市のソープ｜シティヘブンネット" />
                            </a>
                        </div>

                    </div>
                    <div className="bnr -small">
                        <a href="https://yarowork.jp/kyushu/search/?pref%5B%5D=43" target="_blank"><img src="https://yarowork.jp/img/banner_link/yaroworkbnr_468x60.jpg" alt="熊本県の高収入求人情報 野郎WORK" /></a>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default Enterance;